
import { GET_REWARDS, GET_REWARDS_DETAILS, REDEEM_REWARD, BEFORE_REWARDS } from '../../../redux/types';
const initialState = {
    getRewardsRes: {},
    getRewardsDetailsRes: {},
    redeemRewardRes: {},
    getRewardsAuth: false,
    getRewardsDetailsAuth: false,
    redeemRewardAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REWARDS:
            return {
                ...state,
                getRewardsRes: action.payload,
                getRewardsAuth: true,
            }
        case GET_REWARDS_DETAILS:
            return {
                ...state,
                getRewardsDetailsRes: action.payload,
                getRewardsDetailsAuth: true,
            }
        case REDEEM_REWARD:
            return {
                ...state,
                redeemRewardRes: action.payload,
                redeemRewardAuth: true,
            }
        case BEFORE_REWARDS:
            return {
                ...state,
                getRewardsAuth: false,
                getRewardsDetailsAuth: false,
                redeemRewardAuth: false
            }
        default:
            return {
                ...state
            }
    }
}