import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';

const Msg = (props, { closeToast }) => (
    <div className="alert compl-alert">
        <span className="compl-alert-text">{(props.emailverified && props.emailverified !== null) ? "Please complete your profile" : "Please verify your email"}</span>
        {
            props.emailverified ? <button className="btn btn-sm btn-default" onClick={props.goto}>Profile</button> : ''
        }

    </div>
)
class TopSubMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: ''
        }
    }

    componentDidMount() {
        if (this.props.location.pathname === '/practice') {
            $('#practice').css('color', '#000');
            // $("#contest").css('color', '#fff');
        } else if (this.props.location.pathname === '/contest') {
            // $('#practice').css('color', '#fff');
            $("#contest").css('color', '#000');
        }

        let token = localStorage.getItem('token-site');
        if (token) {
            this.setState({
                token
            })
        }
    }

    showToast = (emailverified, compName) => {
        if (this.state.token) {
            if (!toast.isActive(this.toastId)) {
                this.toastId = toast(<Msg goto={this.goto} emailverified={emailverified} />, { autoClose: false }, {
                    toastId: 'profile1'
                });
            }
        } else {
            if (compName === 'practice') {
                this.props.history.push('/practice');
            }
            else {
                this.props.history.push('/contest');
            }
        }
    }

    goto = () => {
        this.props.history.push('/profile')
    }

    render() {
        let profile = this.props.headerProfile.data;
        return (
            <div className="top-sub-menu">
                <div className="container">
                    <ul className="inline">
                        {
                            (profile && profile.firstName !== '' && profile.fatherName !== undefined && profile.dob !== undefined
                                && profile.instituteName !== undefined && profile.grade !== null) ?
                                <li>
                                    <NavLink to="/practice" className="submenu-item"><i className="flaticon-practice"></i><span>Practice</span></NavLink>
                                </li>
                                :
                                <li>
                                    <span id="practice" className="submenu-item cur-poi" onClick={() => this.showToast(true, 'practice')}><i className="flaticon-practice"></i><span>Practice</span></span>
                                </li>
                        }
                        {
                            (profile && profile.firstName !== '' && profile.fatherName !== undefined && profile.dob !== undefined && profile.instituteName !== undefined && profile.city !== undefined
                                && profile.grade !== null) ?
                                <li>
                                    <NavLink to="/contest" className="submenu-item"><i className="flaticon-quiz"></i><span>Contest Quiz</span></NavLink>
                                </li>
                                :
                                <li>
                                    <span id="contest" onClick={() => this.showToast(true, 'contest')} className="submenu-item cur-poi"><i className="flaticon-quiz"></i><span>Contest Quiz</span></span>
                                </li>
                        }
                        {/* <li><NavLink to="/prize" className="submenu-item"><i className="fas fa-trophy"></i><span>Prizes</span></NavLink></li> */}
                        <li><NavLink to="/top-mathletes" className="submenu-item"><i className="flaticon-top-mathlete"></i><span>Rankings</span></NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    headerProfile: state.header.getStudentPtofRes
});

export default connect(
    mapStateToProps,
    null,
)(withRouter(TopSubMenu));