
import { CREATE_QUIZ, SEEN_QUESTION, MARK_ANSWER, GET_QUIZ_ATTEMPT, MARK_SEEN_QA, QUIT_QUIZ, QUESTION_REPORT_PROBLEM, BEFORE_CREATE_QUIZ} from '../../../redux/types';
const initialState = {
    createQuizRes: {},
    quitQuizRes: {},
    getQuizRes: {},
    seenQuestionRes: {},
    seenQuestionQARes: {},
    markAnswerRes: {},
    reportProblemRes: {},
    reportProblemAuth: false,
    createQuizAuth: false,
    getQuizAuth: false,
    seenQuestionAuth: false,
    seenQuestionQAAuth: false,
    markAnswerAuth: false,
    quitQuizAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_QUIZ:
            return {
                ...state,
                createQuizRes: action.payload,
                createQuizAuth: true,
            }
        case GET_QUIZ_ATTEMPT:
            return {
                ...state,
                getQuizRes: action.payload,
                getQuizAuth: true,
            }
        case SEEN_QUESTION:
            return {
                ...state,
                seenQuestionRes: action.payload,
                seenQuestionAuth: true,
            }
        case MARK_SEEN_QA:
            return {
                ...state,
                seenQuestionQARes: action.payload,
                seenQuestionQAAuth: true,
            }
        case MARK_ANSWER:
            return {
                ...state,
                markAnswerRes: action.payload,
                markAnswerAuth: true,
            }
        case QUIT_QUIZ:
            return {
                ...state,
                quitQuizRes: action.payload,
                quitQuizAuth: true,
            }
        case QUESTION_REPORT_PROBLEM:
            return {
                ...state,
                reportProblemRes: action.payload,
                reportProblemAuth: true,
            }
        case BEFORE_CREATE_QUIZ:
            return {
                ...state,
                createQuizAuth: false,
                seenQuestionAuth: false,
                markAnswerAuth : false,
                getQuizAuth : false,
                seenQuestionQAAuth : false,
                quitQuizAuth: false,
                reportProblemAuth: false
            }    
        default:
            return {
                ...state
            }
    }
}