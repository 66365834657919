import { MAKE_PAYMENT, BEFORE_PAYMENT } from '../../../redux/types'
const initialState = {
    payment: {},
    makePaymentAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MAKE_PAYMENT:
            return {
                ...state,
                payment: action.payload,
                makePaymentAuth: true,
            }
        case BEFORE_PAYMENT:
            return {
                ...state,
                payment: {},
                makePaymentAuth: false
            }
        default:
            return {
                ...state
            }
    }
}