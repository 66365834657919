import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Waypoint } from 'react-waypoint';
import $ from 'jquery';
import './footerMathlete.css'

class FooterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showImageDivider: true,
			settingRes: {},
			footerLinks: true
		};
	}

	componentDidMount() {
		let path = window.location.pathname;
		let mobile = localStorage.getItem('mobile-app')
		if (mobile === 'true') {
			this.setState({
				footerLinks: false
			})
		}
		if (path === "/") {
			this.setState({ showImageDivider: false, homepage: true })
		}
	}

	handleScroll = () => {
		const $ = window.$;

		const headerHeight = $('#header').innerHeight();
		if (window.pageYOffset >= headerHeight) {
			$('.project-by').css('display', 'none');
		}
		else if (window.pageYOffset < headerHeight) {
			$('.project-by').css('display', 'block');
		}
	};

	_handleWaypointEnter = (props) => {
		$('#footer').addClass('show-badges');
	}
	_handleWaypointLeave = (props) => {
		$('#footer').removeClass('show-badges');
	}

	goToUrl(url) {
		window.open(url, "_system");
	}

	notifyWarning = (msg, toastId) => {
		toast.warning(msg, {
			toastId
		});
	}

	goToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	navigateToLink(e) {
		let targetUrl = $(e.target).attr("to");
		if (!targetUrl) {
			let elementWithUrl = $(e.target).parents('[to]').first();
			targetUrl = $(elementWithUrl).attr("to");
		}
		localStorage.setItem('quit-target-url', targetUrl);
		if (this.props.location.pathname.indexOf("/quiz/") > -1) {
			window.jQuery("#exitQuizModal").modal('show');
		} else {
			console.log("else target url");
			if ($(e.target).attr("reload")) {
				window.location.href = targetUrl;
			}
			else {
				this.props.history.push(targetUrl);
			}
		}
	}

	render() {
		let { data } = this.props.settings.getSettingsRes;
		const { footerLinks } = this.state

		return (
			<React.Fragment>
				<footer id="footer" className={this.state.homepage ? "home-footer" : ""}>
					<div className="container">
						<div className="footer-logo-holder text-center mb-4">
							<strong className="d-inline-block align-top mb-4">
								<span className="d-inline-block align-top footer-svg cur-poi" onClick={() => this.goToTop()}>
									<img className="img-fluid" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584993/mathlete/assets/winpanda-site-images/win-panda-logo_swtpvf.svg'} alt="Everyone is a winner" />
								</span>
								<img className="cur-poi flag flag-wwf" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584987/mathlete/assets/winpanda-site-images/flagwwf_aeynpy.svg'} alt="wwf LOGO" onClick={() => this.goToUrl('https://www.wwfpak.org/')} />
								<img className="cur-poi flag flag-arhamsoft" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584987/mathlete/assets/winpanda-site-images/flag-mathlete_n2jem8.svg'} alt="Mathlete LOGO" onClick={() => this.goToUrl('https://www.mathlete.pk/')} />
							</strong>
							<span className="d-block pwrd-by">Powered by <span className="cur-poi" onClick={() => this.goToUrl('https://www.mathlete.pk/')}>Mathlete - Let's Play Math!</span></span>
						</div>

						{
							footerLinks &&
							<div className="apps-btns-holder text-center mb-4 mb-md-5">

								{(data && data.iosUrl) ?
									<span className="cur-poi d-inline-block align-top mx-2" onClick={() => this.goToUrl(data.iosUrl)}>
										<img src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584986/mathlete/assets/winpanda-site-images/btn-app-store_z04plg.svg'} alt="App Store" />
									</span>
									:
									<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s iOS app will be available soon', 'iOS-toast')}>
										<img src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584986/mathlete/assets/winpanda-site-images/btn-app-store_z04plg.svg'} alt="Play Store" />
									</span>
								}
								{(data && data.androidUrl) ?
									<span className="cur-poi d-inline-block align-top mx-2" onClick={() => this.goToUrl(data.androidUrl)}>
										<img src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584987/mathlete/assets/winpanda-site-images/btn-google-play_efetx3.svg'} alt="Play Store" />
									</span>
									:
									<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s android app will be available soon', 'android-toast')}>
										<img src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584987/mathlete/assets/winpanda-site-images/btn-google-play_efetx3.svg'} alt="Play Store" />
									</span>
								}



								{/* <span className="cur-poi d-inline-block align-top mx-2">
								<img src={imgPlayStore} alt="Play Store" />
							</span>
							<span className="cur-poi d-inline-block align-top mx-2">
								<img src={imgAppStore} alt="Play Store" />
							</span> */}
							</div>
						}

						<ul className="social-links text-center mb-4 mb-md-5">
							<li className="d-inline-block align-top">
								{(data && data.fbLink) ?
									<span style={{ 'cursor': 'pointer' }} className="d-flex justify-content-center align-items-center" onClick={() => this.goToUrl(data.fbLink)}>
										<i className="fab fa-facebook-f"></i>
									</span>
									:
									<span style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Facebook link will be available soon', 'fb-toast')}>
										<i className="fab fa-facebook-f"></i>
									</span>
								}
							</li>
							<li className="d-inline-block align-top">
								{(data && data.twitterLink) ?
									<span style={{ 'cursor': 'pointer' }} className="d-flex justify-content-center align-items-center" onClick={() => this.goToUrl(data.twitterLink)}>
										<i className="fab fa-twitter"></i>
									</span>
									:
									<span style={{ 'cursor': 'pointer' }} className="d-flex justify-content-center align-items-center" onClick={() => this.notifyWarning('Note! Twitter link will be available soon', 'tw-toast')}>
										<i className="fab fa-twitter"></i>
									</span>
								}
							</li>
							<li className="d-inline-block align-top">
								{(data && data.linkedInLink) ?
									<span style={{ 'cursor': 'pointer' }} className="d-flex justify-content-center align-items-center" onClick={() => this.goToUrl(data.linkedInLink)}>
										<i className="fab fa-instagram"></i>
									</span>
									:
									<span style={{ 'cursor': 'pointer' }} className="d-flex justify-content-center align-items-center" onClick={() => this.notifyWarning('Note! Instagram link will be available soon', 'tw-toast')}>
										<i className="fab fa-instagram"></i>
									</span>
								}
							</li>
						</ul>
						<ul className="footer-nav mb-4 text-center">
							<li className="d-inline-block align-top"><Link to="/">Home</Link></li>
							<li className="d-inline-block align-top"><Link to="/top-mathletes">Rankings</Link></li>
							<li className="d-inline-block align-top"><Link to="/faqs">FAQs</Link></li>
							<li className="d-inline-block align-top"><Link to="/contact-us">Contact</Link></li>
							<li className="d-inline-block align-top"><Link to="/privacy-policy">Privacy Policy</Link></li>
							<li className="d-inline-block align-top"><Link to="/terms-of-service">Terms of Services</Link></li>
						</ul>
						{/* <ul className="footer-contact-list text-center mb-4">
							<li className="d-inline-block align-top">
								<div className="d-flex align-items-center">
									<i className="contact-icon mr-2 far fa-envelope"></i>
									<a href="mailto:support-team@mathwin.com">support-team@mathwin.com</a>
								</div>
							</li>
							<li className="d-inline-block align-top">
								<div className="d-flex align-items-center">
									<i className="contact-icon mr-2 fas fa-mobile-alt"></i>
									<a href="tel:+9242750111">+9242750111</a>
								</div>
							</li>
							<li className="d-inline-block align-top">
								<div className="d-flex align-items-center">
									<i className="contact-icon mr-2 fab fa-whatsapp"></i>
									<a href="#">+92 332 7590111</a>
								</div>
							</li>
						</ul> */}
						<Waypoint onEnter={this._handleWaypointEnter} onLeave={this._handleWaypointLeave}>
							<div className="copyright-text text-center">Copyrights &copy; WinPanda. All Rights Reserved.</div>
						</Waypoint>
					</div>
					<div className="footer-mountain-front">
						<img className="img-fluid" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584986/mathlete/assets/winpanda-site-images/banner-mountain-front_w91fxk.png'} alt="Mountain Front" />
					</div>
					<div className="footer-mountain-back">
						<img className="img-fluid" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584986/mathlete/assets/winpanda-site-images/banner-mountain-back_tpc0e6.png'} alt="Mountain Back" />
					</div>
					<span className="footer-logo wwf-logo">
						<img className="img-fluid cur-poi" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584987/mathlete/assets/winpanda-site-images/footer-wwf_jwyswg.png'} alt="wwf LOGO" onClick={() => this.goToUrl('https://www.wwfpak.org/')} />
					</span>
					<span className="footer-logo arhamsoft-logo">
						<img className="img-fluid cur-poi" src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584988/mathlete/assets/winpanda-site-images/footer-arhamsoft_dswav3.png'} alt="Project By ArhamSoft" onClick={() => this.goToUrl('https://www.arhamsoft.com/')} />
					</span>
				</footer>
				{/* <footer id="footer">
					<div className="exciting-prizes">
						<div className="heading-section">
							<div className="container">
								<div className="blue">
									<span>Exciting Prizes Awaits You</span>
								</div><br />
								<div className="orange">
									<span>Are you ready?</span>
								</div>
							</div>
						</div>
					</div>
					<div className="prize-section">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="footer-image-holder">
										<img src={require('../../../assets/images/footer-image-01.png')} alt="" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="text-section text-center text-md-right">
										<div className="d-inline-block align-top">
											<span className="prize-text mb-3 d-block text-left">Play Math with
											<br />
											<span className="yellow d-block text-left">Mathlete.pk</span></span>
											<div className="download-app text-left pt-3">
												<h4 className="text-white">Download Mathlete App</h4>
												<div className="buttons">
													{
														(data && data.iosUrl) ?
															<span className="cur-poi" onClick={() => this.goToUrl(data.iosUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Download Mathlete App"/></span>
															:
															<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s iOS app will be available soon', 'iOS-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Download Mathlete App"/></span>
													}

													{
														(data && data.androidUrl) ?
															<span className="cur-poi" onClick={() => this.goToUrl(data.androidUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Download Mathlete App"/></span> :
															<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s android app will be available soon', 'android-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Download Mathlete App" /></span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="footer-nav-holder">
										<ul className="list-unstyled text-center">
											<li><Link to="/">Home</Link></li>
											<li><Link to="/campaigns">Campaigns</Link></li>
											<li><Link to="/faqs">FAQs</Link></li>
											<li><Link to="/about-us">About Us</Link></li>
											<li><Link to="/contact-us">Contact Us</Link></li>
										</ul>
										<ul className="list-unstyled text-center">
											<li><Link to="/privacy-policy">Privacy Policy</Link></li>
											<li><Link to="/terms-of-service">Terms of Services</Link></li>
										</ul>
									</div>
									<div className="social-links">
										<ul>
											<li>
												{
													(data && data.fbLink) ?
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.fbLink)}> <span className="fab fa-facebook-f" /></a>
														:
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Facebook link will be available soon', 'fb-toast')}> <span className="fab fa-facebook-f" /></a>
												}
											</li>
											<li>
												{
													(data && data.twitterLink) ?
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.twitterLink)}> <span className="fab fa-twitter" /></a>
														:
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Twitter link will be available soon', 'tw-toast')}> <span className="fab fa-twitter" /></a>
												}
											</li>
											<li>
												{
													(data && data.linkedInLink) ?
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.linkedInLink)}> <span className="fab fa-instagram" /></a>
														:
														<a style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Instagram link will be available soon', 'tw-toast')}> <span className="fab fa-instagram" /></a>
												}
											</li>
										</ul>
									</div>
									<div className="copyright-text text-center">
										<p>Mathlete.pk &copy; All Rights Reserved </p>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</footer> */}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	settings: state.siteSettings,
});

export default connect(
	mapStateToProps,
	null,
)(withRouter(FooterComponent));