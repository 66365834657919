import React from 'react';
//importing layouts....
import DefaultLayout from './layouts/defaultLayout/defaultLayout';
import AuthLayout from './layouts/authLayout/authLayout';
import QuizLayout from './layouts/quizLayout/quizLayout';
import HowToPlayLayout from './layouts/howToPlayLayout/howToPlayLayout';

//importing components....
// import DirectLoginComponent from './components/auth/directLogin/directLogin';
const DirectLoginComponent = React.lazy(() => import('./components/auth/directLogin/directLogin'));
// import LogoutAndClearSession from './components/auth/logoutAndClearSession/logoutAndClearSession';
const LogoutAndClearSession = React.lazy(() => import('./components/auth/logoutAndClearSession/logoutAndClearSession'));
// import FaqComponent from './components/faqComponent/faqComponent';
const FaqComponent = React.lazy(() => import('./components/faqComponent/faqComponent'));
// import LoginComponent from './components/auth/login/loginComponent';
const LoginComponent = React.lazy(() => import('./components/auth/login/loginComponent'));
// import SignupComponent from './components/auth/signup/signupComponent';
const SignupComponent = React.lazy(() => import('./components/auth/signup/signupComponent'));
// import ForgetPasswordComponent from './components/auth/forgetPassword/forgetPasswordComponent';
const ForgetPasswordComponent = React.lazy(() => import('./components/auth/forgetPassword/forgetPasswordComponent'));
const MathleteAccountComponent = React.lazy(() => import('./components/auth/mathleteAccount/mathleteAccountComponent'));
// import ResetPasswordComponent from './components/auth/resetPassword/resetPasswordComponent';
const ResetPasswordComponent = React.lazy(() => import('./components/auth/resetPassword/resetPasswordComponent'));
// import VerifyEmailComponent from './components/userProfile/verifyEmailComponent';
// import ContactusComponent from './components/contactus/contactusComponent';
const ContactusComponent = React.lazy(() => import('./components/contactus/contactusComponent'));
// import DashboardComponent from './components/dashboard/dashboardComponent';
const DashboardComponent = React.lazy(() => import('./components/dashboard/dashboardComponent'));
// import Instruction from './components/howToPlay/instruction';
const Instruction = React.lazy(() => import('./components/howToPlay/instruction'));
// import TimeLine from './components/howToPlay/timeLine';
const TimeLine = React.lazy(() => import('./components/howToPlay/timeLine'));
// import EvaluationCriteria from './components/howToPlay/evaluationCriteria';
const EvaluationCriteria = React.lazy(() => import('./components/howToPlay/evaluationCriteria'));
// import Practice from './components/practice/practice';
const Practice = React.lazy(() => import('./components/practice/practice'));
// import Quiz from './components/quiz/quiz';
const Quiz = React.lazy(() => import('./components/quiz/quiz'));
// import QuizResults from './components/quizResults/quizRessults';
const QuizResults = React.lazy(() => import('./components/quizResults/quizRessults'));
// import Prize from './components/prize/prize';
const Prize = React.lazy(() => import('./components/prize/prize'));
// import AboutUs from './components/aboutUs/aboutUs';
const AboutUs = React.lazy(() => import('./components/aboutUs/aboutUs'));
// import Home from './components/home/home';
// import ContestComponent from './components/contest/contestComponent';
const ContestComponent = React.lazy(() => import('./components/contest/contestComponent'));
// import TopMathletesComponent from './components/topMathletes/topMathletesComponent';
const TopMathletesComponent = React.lazy(() => import('./components/topMathletes/topMathletesComponent'));
// import UserProfileComponent from './components/userProfile/userProfileComponent';
const UserProfileComponent = React.lazy(() => import('./components/userProfile/userProfileComponent'));
// import UserPasswordComponent from './components/userProfile/userPasswordComponent';
const UserPasswordComponent = React.lazy(() => import('./components/userProfile/userPasswordComponent'));
// import Deals from './components/deals/deals';
const Cart = React.lazy(() => import('./components/cart/cart'));
const Checkout = React.lazy(() => import('./components/cart/checkout'));
const Rewards = React.lazy(() => import('./components/rewards/rewards'));
const RewardDetails = React.lazy(() => import('./components/rewards/rewardDetails'));
// import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
const PrivacyPolicy = React.lazy(() => import('./components/privacyPolicy/privacyPolicy'));
// import TermsOfService from './components/termsOfService/termsOfService';
const TermsOfService = React.lazy(() => import('./components/termsOfService/termsOfService'));
// import ErrorComponent from './shared/components/error/errorComponent';
const ErrorComponent = React.lazy(() => import('./shared/components/error/errorComponent'));
// import EmailVerification from './components/userProfile/emailVerification';
const EmailVerification = React.lazy(() => import('./components/userProfile/emailVerification'));
// import Home from './components/home/home';
const Home = React.lazy(() => import('./components/home/home'));

const Order = React.lazy(() => import('./components/order/order'))
const OrderHistory = React.lazy(()=> import('./components/order/orderHistory'))

const WinningMathletes = React.lazy(() => import('./components/winning-mathletes/winning-mathlets'));

const routes = [
    { path: "/logout-and-clear-session", access: true, exact: true, name: "Logout & Clear Session", layout: AuthLayout, component: LogoutAndClearSession },
    { path: "/", access: true, exact: true, name: "home", layout: DefaultLayout, component: Home },
    { path: "/profile", access: true, exact: true, name: "userprofile", layout: DefaultLayout, component: UserProfileComponent },
    { path: "/update-password", access: true, exact: true, name: "userpassword", layout: DefaultLayout, component: UserPasswordComponent },
    { path: "/cart", access: true, exact: true, name: "cart", layout: DefaultLayout, component: Cart },
    { path: "/checkout", access: true, exact: true, name: "cart", layout: DefaultLayout, component: Checkout },
    { path: "/rewards/:id?", access: true, exact: true, name: "rewards", layout: DefaultLayout, component: Rewards },
    { path: "/reward-details/:rewardId", access: true, exact: true, name: "detail-details", layout: DefaultLayout, component: RewardDetails },
    { path: "/faqs", access: true, exact: true, name: "faq", layout: DefaultLayout, component: FaqComponent },
    { path: "/privacy-policy", access: true, exact: true, name: "privacy-policy", layout: DefaultLayout, component: PrivacyPolicy },
    { path: "/terms-of-service", access: true, exact: true, name: "terms-of-service", layout: DefaultLayout, component: TermsOfService },
    { path: "/dashboard/:type?", access: true, exact: true, name: "dashboard", layout: DefaultLayout, component: DashboardComponent },
    { path: "/instruction", access: true, exact: true, name: "instruction", layout: HowToPlayLayout, component: Instruction },
    // { path: "/timeline", access: true, exact: true, name: "timeline", layout: HowToPlayLayout, component: TimeLine },
    { path: "/evaluation-criteria", access: true, exact: true, name: "evaluation-criteria", layout: HowToPlayLayout, component: EvaluationCriteria },
    { path: "/practice", access: true, exact: true, name: "practice", layout: QuizLayout, component: Practice },
    { path: "/quiz/:type?", access: true, exact: true, name: "practice", layout: DefaultLayout, component: Quiz },
    { path: "/quiz-results/:id?", access: true, exact: true, name: "practice", layout: DefaultLayout, component: QuizResults },
    // { path: "/prize", access: true, exact: true, name: "prize", layout: QuizLayout, component: Prize },
    { path: "/about-us", access: true, exact: true, name: "about-us", layout: DefaultLayout, component: AboutUs },
    { path: "/email-verification", access: true, exact: true, name: "emailverification", layout: AuthLayout, component: EmailVerification },
    { path: "/login", access: true, exact: true, name: "login", layout: AuthLayout, component: LoginComponent },
    { path: "/signup", access: true, exact: true, name: "signup", layout: AuthLayout, component: SignupComponent },
    { path: "/forget-password", access: true, exact: true, name: "Forgot password", layout: AuthLayout, component: ForgetPasswordComponent },
    { path: "/math-account", access: true, exact: true, name: "Mathlete Account", layout: AuthLayout, component: MathleteAccountComponent },
    { path: "/reset-password", access: true, exact: true, name: "Reset password", layout: AuthLayout, component: ResetPasswordComponent },
    { path: "/contact-us", access: true, exact: true, name: "contact-us", layout: DefaultLayout, component: ContactusComponent },
    { path: "/contest", access: true, exact: true, name: "contest", layout: QuizLayout, component: ContestComponent },
    { path: "/top-mathletes", access: true, exact: true, name: "top mathletes", layout: QuizLayout, component: TopMathletesComponent },
    { path: "/order-history", access: true, exact: true, name: "deal redeem history", layout: DefaultLayout, component: OrderHistory },
    { path: "/auth/directLogin/:param", access: true, exact: true, name: "direct login", layout: DefaultLayout, component: DirectLoginComponent },
    { path: "/order/:orderId", access: true, exact: true, name: "Order", layout: DefaultLayout, component: Order },
    { path: "/winning-mathletes", access: true, exact: true, name: "Winning Mathletes", layout: DefaultLayout, component: WinningMathletes },
    { path: "/*", access: true, exact: true, name: "Erorr Page", layout: AuthLayout, component: ErrorComponent },

];

export default routes;