
import { GET_DEALS, GET_DEALS_SLIDERS, GET_DEALS_DETAILS, REDEEME_DEAL, BEFORE_DEALS} from '../../../redux/types';
const initialState = {
    getDealsRes: {},
    getDealsSlidersRes: {},
    getDealsDetailsRes: {},
    redeemeDealRes: {},
    getDealsAuth: false,
    getDealsSlidersAuth: false,
    getDealsDetailsAuth: false,
    redeemeDealAuth : false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEALS:
            return {
                ...state,
                getDealsRes: action.payload,
                getDealsAuth: true,
            }
        case REDEEME_DEAL:
            return {
                ...state,
                redeemeDealRes: action.payload,
                redeemeDealAuth: true,
            }
        case GET_DEALS_DETAILS:
            return {
                ...state,
                getDealsDetailsRes: action.payload,
                getDealsDetailsAuth: true,
            }
        case GET_DEALS_SLIDERS:
            return {
                ...state,
                getDealsSlidersRes: action.payload,
                getDealsSlidersAuth: true,
            }
        case BEFORE_DEALS:
            return {
                ...state,
                getDealsAuth: false,
                getDealsSlidersAuth: false,
                getDealsDetailsAuth: false,
                redeemeDealAuth: false
            }    
        default:
            return {
                ...state
            }
    }
}