
import { BEFORE_ORDER, GET_ORDER, GET_ORDERS } from '../../redux/types'
const initialState = {
    getOrder: false,
    getOrders: false,

    order: {},
    orders: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ORDER:
            return {
                ...state,
                getOrder: true,
                order: action.payload
            }
        case GET_ORDERS:
            return {
                ...state,
                getOrders: true,
                orders: action.payload
            }
        case BEFORE_ORDER:
            return {
                ...state,
                getOrder: false,
                getOrders: false,
                order: {},
                orders: []
            }
        default:
            return {
                ...state
            }
    }
}