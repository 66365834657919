
import { CART_DETAILS, ADD_TO_CART, BEFORE_CART, UPDATE_CART, REMOVE_CART_ITEM } from '../../../redux/types';
const initialState = {
    addToCartAuth: false,
    getCartDataAuth: false,
    updateCart: false,
    removeCartItem: false,
    addToCartRes: {},
    getCartDataRes: {},
    updateCartRes: {},
    removeCartItemRes: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                addToCartRes: action.payload,
                addToCartAuth: true,
            }
        case CART_DETAILS:
            return {
                ...state,
                getCartDataRes: action.payload,
                getCartDataAuth: true,
            }
        case UPDATE_CART:
            return {
                ...state,
                updateCartRes: action.payload,
                updateCart: true,
            }
        case REMOVE_CART_ITEM:
            return {
                ...state,
                removeCartItemRes: action.payload,
                removeCartItem: true,
            }
        case BEFORE_CART:
            return {
                ...state,
                addToCartAuth: false,
                getCartDataAuth: false,
                updateCart: false,
                removeCartItem: false,
                addToCartRes: {},
                getCartDataRes: {},
                updateCartRes: {},
                removeCartItemRes: {}
            }
        default:
            return {
                ...state
            }
    }
}