
import { GET_PRACTICE_STATS, GET_DETAILED_STATS, EXPORT_DETAILED_STATS, BEFORE_STATS, } from '../../../redux/types';
const initialState = {
    getPracticeRes: {},
    getDetailsRes: {},
    exportDetailsRes: {},
    getPracticeResAuth: false,
    getDetailsAuth: false,
    exportDetailsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRACTICE_STATS:
            return {
                ...state,
                getPracticeRes: action.payload,
                getPracticeResAuth: true,
            }
        case GET_DETAILED_STATS:
            return {
                ...state,
                getDetailsRes: action.payload,
                getDetailsAuth: true,
            }
        case EXPORT_DETAILED_STATS:
            return {
                ...state,
                exportDetailsRes: action.payload,
                exportDetailsAuth: true,
            }
        case BEFORE_STATS:
            return {
                ...state,
                getPracticeResAuth: false,
                getDetailsAuth : false,
                exportDetailsAuth : false
            }    
        default:
            return {
                ...state
            }
    }
}