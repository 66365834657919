import { UPDATE_STUDENT_PROFILE, BEFORE_STUDENT_PROFILE, UPDATE_STUDENT_PASSWORD, IDENTITY_DOCUMENTS, RECEIPT_DOCUMENTS, CAMP_INFO_STATUS, BEFORE_CAMP_INFO } from '../../../redux/types';

const initialState = {
    updatedStudentRes: {},
    updatePasswordRes: {},
    identityDocumentsRes: {},

    //check GET list API
    identityDocumentsAuth: false,
    authUpdatedStudentRes: false,
    authUpdatePasswordRes: false,

    profileCampInfoStatus: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_STUDENT_PROFILE:
            return {
                ...state,
                updatedStudentRes: action.payload,
                authUpdatedStudentRes: true
            }
        case UPDATE_STUDENT_PASSWORD:
            return {
                ...state,
                updatePasswordRes: action.payload,
                authUpdatePasswordRes: true
            }
        case IDENTITY_DOCUMENTS:
            return {
                ...state,
                identityDocumentsRes: action.payload,
                identityDocumentsAuth: true
            }
        case RECEIPT_DOCUMENTS:
            return {
                ...state,
                receiptsDocumentsRes: action.payload,
                receiptsDocumentsAuth: true
            }
        case CAMP_INFO_STATUS:
            return {
                ...state,
                profileCampInfoStatus: true
            }
        case BEFORE_CAMP_INFO:
            return {
                ...state,
                profileCampInfoStatus: false
            }
        case BEFORE_STUDENT_PROFILE:
            return {
                ...state,
                authUpdatedStudentRes: false,
                authUpdatePasswordRes: false,
                identityDocumentsAuth: false,
                receiptsDocumentsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}