import { APP_URL, BEFORE_APP, INSTRUCTIONS_POPUP_SEEN } from '../../../redux/types';

const initialState = {
    appUrlRes: {},
    instructionsPopupSeenRes: {},
    authAppUrl: false,
    instructionsPopupSeenAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case APP_URL:
            return {
                ...state,
                appUrlRes: action.payload,
                authAppUrl: true,
            }
        case INSTRUCTIONS_POPUP_SEEN:
            return {
                ...state,
                instructionsPopupSeenRes: action.payload,
                instructionsPopupSeenAuth: true,
            }
        case BEFORE_APP:
            return {
                ...state,
                authAppUrl: false,
                instructionsPopupSeenAuth: false,
            }
        default:
            return {
                ...state,
            }
    }
}