
import { QUIZ_RESULTS, BEFORE_CREATE_QUIZ, DOWNLOAD_QUIZ_RESULT, BEFORE_DOWNLOAD_QUIZ_RESULT } from '../../../redux/types';
const initialState = {
    quizResultsRes: {},
    quizResultsAuth: false,

    downloadQuizResultRes: {},
    downloadQuizResultAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case QUIZ_RESULTS:
            return {
                ...state,
                quizResultsRes: action.payload,
                quizResultsAuth: true
            }
        case BEFORE_CREATE_QUIZ:
            return {
                ...state,
                quizResultsAuth: false
            }
        case DOWNLOAD_QUIZ_RESULT:
            return {
                ...state,
                downloadQuizResultRes: action.payload,
                downloadQuizResultAuth: true
            }
        case BEFORE_DOWNLOAD_QUIZ_RESULT:
            return {
                ...state,
                downloadQuizResultAuth: false
            }
        default:
            return {
                ...state
            }
    }
}