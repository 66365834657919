import { GET_DEAL_REDEEM_HISTORY, BEFORE_DEAL_REDEEM_HISTORY } from '../../../redux/types';

const initialState = {
    getDealRedeemHistoryRes: {},
    dealRedeemHistoryAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEAL_REDEEM_HISTORY:
            return {
                ...state,
                getDealRedeemHistoryRes: action.payload,
                dealRedeemHistoryAuth: true
            }
        case BEFORE_DEAL_REDEEM_HISTORY:
            return {
                ...state,
                dealRedeemHistoryAuth: false
            }
        default:
            return {
                ...state
            }
    }
}